import React, { Component } from 'react';

class Registrationexists extends Component {
    render() {
        return (
            <div>
                <div class="container text-center">
                    <div class="header-title text-center mt-5 mb-2">
                    </div>
                    <div className="row">
                        <div className="col-6 offset-3">
                            <div className="alert alert-info text-center my-4">
                                Registration has been done. Please log in to continue.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Registrationexists;
