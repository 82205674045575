import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Registration from '../pages/Registration';
import Login from '../pages/Login';
import InvalidTokenPage from '../pages/InvalidTokenPage';
import Leads from '../pages/Leads';
import Dashboard from '../pages/Dashboard';
import UserRoute from './UserRoute';
import { Switch } from 'react-router-dom';
import Registrationexists from '../pages/RegistrationExists';

function UnprotectedRoutes() {
    
    return (
        <Switch>

            <Route path="/registration/:token" exact component={Registration}/>
            <Route path="/login" exact component={Login}/>
            <Route path="/invalid-token" exact component={InvalidTokenPage}/>
            <Route path="/registration-exists" exact component={Registrationexists}/>

        </Switch>
    );

}

export default UnprotectedRoutes;