import React, { Component } from 'react';
import { request } from '../constants/constants';
import Loader from 'react-loader-spinner';
import UserContext from '../contexts/User/Index';
import moment from 'moment';

class Commissions extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isLoadingTotalEarnedComission: true,
            isLoadingTotalThisMonthPaidCommission: true,
            isLoadingTotalUpcomingCommission: true,
            total_earned_commission: 0,
            curr_month_paid_commission: 0,
            total_upcoming_commission: 0
        };
    }

    componentDidMount(){

        const data = {
            item_id: this.context.user.user.item_id,
        }

        request('POST', '/dashboard/get-commission-earned', data, true).then(res => {
            const total_earned_commission = res.data.data
            const total_commission_earned_details = res.data.details
            this.setState({ total_earned_commission, total_commission_earned_details, isLoadingTotalEarnedComission: false})

        }).catch(err => {
            console.log("err", err)
            this.setState({ isLoadingTotalEarnedComission: false})
        })

        request('POST', '/dashboard/get-this-month-paid-commission', data, true).then(res => {
            const curr_month_paid_commission = res.data.data
            const curr_month_paid_commission_details = res.data.details
            this.setState({ curr_month_paid_commission, curr_month_paid_commission_details, isLoadingTotalThisMonthPaidCommission: false})

        }).catch(err => {
            console.log("err", err)
            this.setState({ isLoadingTotalCurrMonthPaidCommission: false})
        })

        request('POST', '/dashboard/get-total-upcoming-commission', data, true).then(res => {
            const total_upcoming_commission = res.data.data
            const total_upcoming_commission_details = res.data.details
            this.setState({ total_upcoming_commission, total_upcoming_commission_details, isLoadingTotalUpcomingCommission: false})

        }).catch(err => {
            console.log("err", err)
            this.setState({ isLoadingTotalUpcomingCommission: false})
        })
    }


    render() {

        const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

        const d = new Date();
        let name_of_the_month = month[d.getMonth() - 1];

        return (
            <div className="content">
                
                <div className="row">

                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Total Commission Earned 
                            </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                {
                                    this.state.isLoadingTotalEarnedComission ? 
                                        <Loader
                                            type="TailSpin"
                                            color="#000000"
                                            height={50}
                                            width={50}
                                            style={{"margin-left" : "200px", "margin-bottom": "20px"}}
                                        /> : (
                                            <React.Fragment>
                                                <div className="card-body ">
                                                    <p className='font-size-2em'>${this.state.total_earned_commission}</p>                                
                                                    <hr/>
                                                    <div className="row">
                                                        {
                                                            this.state.total_commission_earned_details && this.state.total_commission_earned_details.map(val => {
                                                                
                                                                return <React.Fragment>
                                                                        <div className="col-lg-6 col-md-6 col-md-12">
                                                                            <p>
                                                                                <b>Name: </b>{val.contact_name}<br/>
                                                                                <b>Amount: </b>${val.amount}<br/>
                                                                                <b>Settlement Date: </b>{val.settlement_date && moment(val.settlement_date).format('DD-MMMM-YYYY')}<br/>
                                                                                <b>Paid Date: </b>{val.date_paid && moment(val.date_paid).format('DD-MMMM-YYYY')}<br/>
                                                                            </p>
                                                                        </div>
                                                                    </React.Fragment>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                }
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Total Upcoming Commission
                            </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                {
                                    this.state.isLoadingTotalUpcomingCommission ? 
                                        <Loader
                                            type="TailSpin"
                                            color="#000000"
                                            height={50}
                                            width={50}
                                            style={{"margin-left" : "200px","margin-bottom": "20px"}}
                                        />
                                    : (
                                        <React.Fragment>
                                            <div className="card-body ">
                                                <p className='font-size-2em'>${this.state.total_upcoming_commission}</p>
                                                <hr/>
                                                <div className="row">
                                                    {
                                                        this.state.total_upcoming_commission_details && this.state.total_upcoming_commission_details.map(val => {
                                                            return <React.Fragment>
                                                                    <div className="col-lg-6 col-md-6 col-md-12">
                                                                        <p>
                                                                            <b>Name: </b>{val.contact_name}<br/>
                                                                            <b>Amount: </b>${val.amount}<br/>
                                                                            <b>Settlement Date: </b>{val.settlement_date && moment(val.settlement_date).format('DD-MMMM-YYYY')}<br/>
                                                                            <b>Payment Month: </b>{val.payment_month}<br/>
                                                                        </p>
                                                                    </div>
                                                            </React.Fragment>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                }
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Month of {name_of_the_month} Paid Commission
                            </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                {
                                    this.state.isLoadingTotalThisMonthPaidCommission ? 
                                        <Loader
                                            type="TailSpin"
                                            color="#000000"
                                            height={50}
                                            width={50}
                                            style={{"margin-left" : "200px", "margin-bottom": "20px"}}
                                        />
                                    : (
                                        <React.Fragment>
                                            <div className="card-body">
                                                <p className='font-size-2em'>${this.state.curr_month_paid_commission}</p>
                                                <hr/>
                                                <div className="row">
                                                    {
                                                        this.state.curr_month_paid_commission_details && this.state.curr_month_paid_commission_details.map(val => {
                                                            return <React.Fragment>
                                                                <div className="col-lg-6 col-md-6 col-md-12">
                                                                    <p>
                                                                        <b>Name: </b>{val.contact_name}<br/>
                                                                        <b>Amount: </b>${val.amount}<br/>
                                                                        <b>Settlement Date: </b>{val.settlement_date && moment(val.settlement_date).format('DD-MMMM-YYYY')}<br/>
                                                                        <b>Paid Date: </b>{val.paid_date && moment(val.paid_date).format('DD-MMMM-YYYY')}<br/>
                                                                    </p>
                                                                </div>
                                                            </React.Fragment>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            
                                        </React.Fragment>
                                    )
                                }
                            </div>
                            </div>
                        </div>
                    </div>


                    {/* <div className="col-md-4">
                        <div className="card card-stats">
                            <div className="card-header ">
                                <div className="row">
                                    <div className="col-7 col-md-8">
                                        <h5 className="card-title">Total Commission Earned</h5>
                                    </div>
                                    <div className="col-5 col-md-4">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-money-coins text-success" />
                                        </div>
                                    </div>                               
                                </div>
                            </div>

                            {
                                this.state.isLoadingTotalEarnedComission ? 
                                    <Loader
                                        type="TailSpin"
                                        color="#000000"
                                        height={50}
                                        width={50}
                                        style={{"margin-left" : "200px", "margin-bottom": "20px"}}
                                    /> : (
                                        <React.Fragment>
                                            <div className="card-body ">
                                                <p className='font-size-2em'>{this.state.total_earned_commission}</p>                                
                                            </div>
                                            <div className="card-footer ">
                                                <hr />
                                            </div>
                                            <div className="card-body ">
                                                {
                                                    this.state.total_commission_earned_details && this.state.total_commission_earned_details.map(val => {
                                                        
                                                        return <React.Fragment>
                                                                <p>
                                                                    <b>Name: </b>{val.contact_name}<br/>
                                                                    <b>Amount: </b>{val.amount}<br/>
                                                                    <b>Settlement Date: </b>{val.settlement_date && moment(val.settlement_date).format('DD-MMMM-YYYY')}<br/>
                                                                    <b>Paid Date: </b>{val.date_paid && moment(val.date_paid).format('DD-MMMM-YYYY')}<br/>
                                                                </p>
                                                                
                                                            </React.Fragment>
                                                    })
                                                }

                                            </div>
                                        </React.Fragment>
                                    )
                            }

                            {
                                this.state.total_commission_earned_details && this.state.total_commission_earned_details.length > 0 ? (
                                    <React.Fragment>
                                        <div className="card-footer ">
                                            <hr />
                                        </div>
                                    </React.Fragment>
                                ) : ""
                            }

                        </div>
                    </div> */}
                    {/* <div className="col-md-4">
                        <div className="card card-stats">
                            <div className="card-header ">
                                <div className="row">
                                    <div className="col-7 col-md-8">
                                        <h5 className="card-title">Total Upcoming Commission</h5>
                                    </div>
                                    <div className="col-5 col-md-4">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-money-coins text-success" />
                                        </div>
                                    </div>                               
                                </div>
                            </div>
                            {
                                this.state.isLoadingTotalUpcomingCommission ? 
                                    <Loader
                                        type="TailSpin"
                                        color="#000000"
                                        height={50}
                                        width={50}
                                        style={{"margin-left" : "200px","margin-bottom": "20px"}}
                                    />
                                : (
                                    <React.Fragment>
                                        <div className="card-body ">
                                            <p className='font-size-2em'>{this.state.total_upcoming_commission}</p>
                                        </div>
                                        <div className="card-footer ">
                                            <hr />
                                        </div>
                                        <div className="card-body ">
                                            {
                                                this.state.total_upcoming_commission_details && this.state.total_upcoming_commission_details.map(val => {
                                                    return <React.Fragment>
                                                                <p>
                                                                    <b>Name: </b>{val.contact_name}<br/>
                                                                    <b>Amount: </b>{val.amount}<br/>
                                                                    <b>Settlement Date: </b>{val.settlement_date && moment(val.settlement_date).format('DD-MMMM-YYYY')}<br/>
                                                                    <b>Payment Month: </b>{val.payment_month}<br/>
                                                                </p>
                                                                
                                                            </React.Fragment>
                                                })
                                            }
                                            <>
                                            </>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                            {
                                this.state.total_upcoming_commission_details && this.state.total_upcoming_commission_details.length > 0 ? (
                                    <React.Fragment>
                                        <div className="card-footer ">
                                            <hr />
                                        </div>
                                    </React.Fragment>
                                ) : ""
                            }
                            
                        </div>
                    </div> */}
                    {/* <div className="col-md-4">
                        <div className="card card-stats">
                            <div className="card-header ">
                                <div className="row">
                                    <div className="col-7 col-md-8">
                                        <h5 className="card-title">Month of {name_of_the_month} Paid Commission</h5>
                                    </div>
                                    <div className="col-5 col-md-4">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-money-coins text-success" />
                                        </div>
                                    </div>                               
                                </div>
                            </div>
                            {
                                this.state.isLoadingTotalThisMonthPaidCommission ? 
                                    <Loader
                                        type="TailSpin"
                                        color="#000000"
                                        height={50}
                                        width={50}
                                        style={{"margin-left" : "200px", "margin-bottom": "20px"}}
                                    />
                                : (
                                    <React.Fragment>
                                        <div className="card-body">
                                            <p className='font-size-2em'>{ this.state.curr_month_paid_commission }</p>
                                        </div>
                                        <div className="card-footer">
                                            <hr />
                                        </div>
                                        <div className="card-body">
                                           {
                                               this.state.curr_month_paid_commission_details && this.state.curr_month_paid_commission_details.map(val => {
                                                   return <React.Fragment>
                                                       <p>
                                                            <b>Name: </b>{val.contact_name}<br/>
                                                            <b>Amount: </b>{val.amount}<br/>
                                                            <b>Settlement Date: </b>{val.settlement_date && moment(val.settlement_date).format('DD-MMMM-YYYY')}<br/>
                                                            <b>Paid Date: </b>{val.paid_date && moment(val.paid_date).format('DD-MMMM-YYYY')}<br/>
                                                        </p>
                                                   </React.Fragment>
                                               })
                                           }
                                        </div>
                                        
                                    </React.Fragment>
                                )
                            }
                            {
                                this.state.curr_month_paid_commission_details && this.state.curr_month_paid_commission_details.length > 0 ? (
                                    <React.Fragment>
                                        <div className="card-footer ">
                                            <hr />
                                        </div>
                                    </React.Fragment>
                                ) : ""
                            }
                            
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default Commissions;
