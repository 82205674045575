import moment from 'moment';
import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { request } from '../constants/constants';
import UserContext from '../contexts/User/Index';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'

class Propertiesreport extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            properties: [],
            isLoading: true,
        };
    }

    componentDidMount(){
        const data = {
            item_id: this.context.user.user.item_id,
        }

        request('POST', '/dashboard/get-properties-board-data', data, true).then(res => {
            const properties = res.data.data.map(obj => ({
                id: obj.id,
                name: obj.name,
                status: obj.status,
                property_address: obj.property_address,
                // partners: obj.partners,
                property_type: obj.property_type,
                date_entered: obj.date_entered,
                no_of_bedrooms: obj.no_of_bedrooms,
                no_of_bathrooms: obj.no_of_bathrooms,
                no_of_storage: obj.no_of_storage,
                flat_land: obj.flat_land,
                building_age: obj.building_age,
                land_size: obj.land_size,
                internal_size: obj.internal_size,
                newly_renovated: obj.newly_renovated,
                walking_distance_to_schools: obj.walking_distance_to_schools,
                walking_distance_to_shopping: obj.walking_distance_to_shopping,
                development_potential: obj.development_potential,
                swimming_pool: obj.swimming_pool,
                price_range: obj.price_range
            }));
             
            this.setState({ properties, isLoading: false})

        }).catch(err => {
            console.log("err", err)
            this.setState({ isLoading: false})
        })
    }

    render() {
        
        const columns = [
            {
                Header: () => (<span className='fw-bold'>Property Name</span>),
                width: 250,
                id: "property_name",
                accessor: "property_name",
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && row.original.name}</span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Property Address</span>),
                width: 350,
                id: "property_address",
                accessor: "property_address",
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && row.original.property_address}</span>
                        </React.Fragment>
                },
            },
            // {
            //     Header: 'Partners',
            //     id: "partners",
            //     accessor: "partners",
            //     Cell: row => {
            //         return <React.Fragment>
            //                 <span className="center"> { row.original && row.original.partners}</span>
            //             </React.Fragment>
            //     },
            // },
            {
                Header: () => (<span className='fw-bold'>Property Type</span>),
                width: 250,
                id: "property_type",
                accessor: "property_type",
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && row.original.property_type}</span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Date Entered</span>),
                width: 250,
                id: "date_entered",
                accessor: "date_entered",
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && (row.original.date_entered === undefined || row.original.date_entered === null ? moment(row.original.date_entered).format('DD-MMMM-YYYY') : "")}</span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>No Of Bedrooms</span>),
                width: 250,
                id: "no_of_bedrooms",
                accessor: "no_of_bedrooms",
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && row.original.no_of_bedrooms}</span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>No Of Bathrooms</span>),
                width: 250,
                id: "no_of_bathrooms",
                accessor: "no_of_bathrooms",
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && row.original.no_of_bathrooms}</span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>No Of Storage</span>),
                width: 250,
                id: "no_of_storage",
                accessor: "no_of_storage",
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && row.original.no_of_storage}</span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Flat Land</span>),
                width: 250,
                id: "flat_land",
                accessor: "flat_land",
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && row.original.flat_land}</span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Building Age</span>),
                width: 250,
                id: "building_age",
                accessor: "building_age",
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && row.original.building_age}</span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Land Size</span>),
                width: 250,
                id: "land_size",
                accessor: "land_size",
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && row.original.land_size}</span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Internal Size</span>),
                width: 250,
                id: "internal_size",
                accessor: "internal_size",
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && row.original.internal_size}</span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Newly Renovated</span>),
                width: 250,
                id: "newly_renovated",
                accessor: "newly_renovated",
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && row.original.newly_renovated}</span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Walking Distance To Schools</span>),
                width: 250,
                id: "walking_distance_to_schools",
                accessor: "walking_distance_to_schools",
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && row.original.walking_distance_to_schools}</span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Walking Distance To Shopping</span>),
                width: 250,
                id: "walking_distance_to_shopping",
                accessor: "walking_distance_to_shopping",
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && row.original.walking_distance_to_shopping}</span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Development Potential</span>),
                width: 250,
                id: "development_potential",
                accessor: "development_potential",
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && row.original.development_potential}</span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Swimming Pool</span>),
                width: 250,
                id: "swimming_pool",
                accessor: "swimming_pool",
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && row.original.swimming_pool}</span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Price Range</span>),
                width: 250,
                id: "price_range",
                accessor: "price_range",
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && row.original.price_range}</span>
                        </React.Fragment>
                },
            },
        ];

        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="card ">
                        <div className="container-fluid dashboard">
                            {
                                this.state.isLoading ? (
                                    <Loader
                                        type="TailSpin"
                                        color="#000000"
                                        height={100}
                                        width={100}
                                        style={{"margin-top" : "100px", "margin-left" : "700px", "margin-bottom" : "100px"}}
                                    />
                                ) : (

                                    this.state.properties.length && this.state.properties.length == 0 ? (
                                        <h5>Please refresh the page</h5>
                                    ) : (

                                        <ReactTable
                                            data={this.state.properties}
                                            columns={columns}
                                            defaultExpanded={true}
                                            minRows={10}
                                            onResizedChange={this.getResizeValues} 
                                        />
                                    )
                                    
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>            
        );
    }
}

export default Propertiesreport;
