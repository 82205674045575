import React, { Component } from 'react';
import { request } from '../constants/constants';
import UserContext from '../contexts/User/Index';

class Dashboard extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            lead_conversion_rate: 0,
            total_leads_for_this_month: 0,
            total_closed_leads_for_this_month: 0            
        }
    }

    componentDidMount(){
        
        const item_id = this.context.user.user.item_id

        request('GET', `/dashboard/get-lead-conversion-report/${item_id}`, {}, true).then(res => {

            const lead_conversion_rate = res.data.data
            const total_leads_for_this_month = res.data.total_leads_for_this_month
            const total_closed_leads_for_this_month = res.data.total_closed_leads_for_this_month

            this.setState({ lead_conversion_rate, total_leads_for_this_month, total_closed_leads_for_this_month, isLoading: false})

        }).catch(err => {
            console.log("err", err)
            this.setState({ isLoading: false})
        })
            
    }
    
    render() {
        return (
            <div>
                <div className='row'>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="card card-stats">
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-5 col-md-4">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="fa fa-percent text-success" />
                                    </div>
                                    </div>
                                    <div className="col-7 col-md-8">
                                    <div className="numbers">
                                        <p className="card-category">Total leads for this month</p>
                                        <p className="card-title">{this.state.total_leads_for_this_month}</p><p>
                                        </p></div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer ">
                                <hr />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="card card-stats">
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-5 col-md-4">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="fa fa-percent text-success" />
                                    </div>
                                    </div>
                                    <div className="col-7 col-md-8">
                                    <div className="numbers">
                                        <p className="card-category">Total Closed Leads for this month</p>
                                        <p className="card-title">{this.state.total_closed_leads_for_this_month}</p><p>
                                        </p></div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer ">
                                <hr />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="card card-stats">
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-5 col-md-4">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="fa fa-percent text-success" />
                                    </div>
                                    </div>
                                    <div className="col-7 col-md-8">
                                    <div className="numbers">
                                        <p className="card-category">Lead Conversion Rate</p>
                                        <p className="card-title">{this.state.lead_conversion_rate}</p><p>
                                        </p></div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer ">
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

        );
    }
}

export default Dashboard;
