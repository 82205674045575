import React, { Component } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css';
import { request } from '../constants/constants';
import UserContext from '../contexts/User/Index';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import "../App.css"
import { Modal } from 'react-bootstrap';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

class Leads extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            leads: [],
            property_match_details: [],
            client_property_references: [],
            matched_columns: [],
            matched_columns_con: [],
            con: [],
            contacts: [],
            lead_sources: [],
            client_stages: [],
            our_stages: [],
            client_preferences: [],
            building_age_column_values: [],
            development_potential_column_values: [],
            flat_land_column_values: [],
            internal_size_column_values: [],
            land_size_column_values: [], 
            newly_renovated_column_values: [],
            no_of_bathrooms_column_values: [],
            no_of_bedrooms_column_values: [],
            no_of_storage_column_values: [],
            price_range_column_values: [],
            property_type_column_values: [],
            swimming_pool_column_values: [],
            walking_distance_to_schools_column_values: [],
            walking_distance_to_shopping_column_values: [],
            statement: "",
            cur_lead_id: "",
            cur_actual_loan_amount: "",
            cur_client_stage: "",
            cur_contacts: "",
            cur_deal_notes: "",
            cur_estimated_loan_amount: "",
            cur_lead_source: "",
            cur_name: "",
            cur_our_stage: "",
            group_id: "",
            client_pref_id: "",
            item_name: "",
            newly_added_item_id: "",
            client_pref_board_id: "",
            lead_board_id: "",
            client_pref_action: "",
            board_group_ids: [],
            isLoading: true,
            isLoadingPropertyMatchDetails: false,
            showEditLeadModal: false,
            isLoadingEditModal: false,
            isLoadingDeleteModal: false,
            showDeleteLeadModal: false,
            showAddNewLeadModal: false,
            isLoadingClientPreferences: false,
            showSuccessProcessMessage: "none",
            showDivForm: "block",
            showPropertyModal: false,
            showEditClientPrefModal: false
        };
    }

    componentDidMount(){

        this.getMondayData();

        const data = {
            item_id: this.context.user.user.item_id,
            board_id: this.context.user.user.board_id,
            email : this.context.user.user.email
        }

        request('GET', '/dashboard/get-all-contacts', data, true).then(res => {

            const contacts = res.data.data;
            this.setState({ contacts })

        }).catch(err => {
            console.log("err", err)
            this.setState({ isLoading: false})
        })

    }

    getMondayData = () => {

        const data = {
            item_id: this.context.user.user.item_id,
            board_id: this.context.user.user.board_id,
            email : this.context.user.user.email
        }

        request('POST', '/dashboard/get-monday-data', data, true).then(res => {
            // console.log("res", res)
            const board_id = res.data.board_id
            const leads = res.data.data.map(obj => ({
                id: obj.id ?? "",
                name: obj.name ?? "",
                open_inspection_address: obj.open_inspection_address ?? "",
                lead_source: obj.lead_source ?? "",
                client_stage: obj.client_stage ?? "",
                our_stage: obj.our_stage ?? "",
                lead_creation_date: obj.lead_creation_date ? moment(obj.lead_creation_date).format('DD-MMMM-YYYY') : "",
                lead_close_date: obj.lead_close_date ? moment(obj.lead_close_date).format('DD-MMMM-YYYY') : "",
                estimated_loan_amount: obj.estimated_loan_amount ?? "",
                actual_loan_amount: obj.actual_loan_amount ?? "",
                commission_type: obj.commission_type ?? "",
                deal_notes: obj.deal_notes ?? "",
                contacts: obj.contacts ?? "",

            }));
             
            this.setState({ 
                leads, 
                leads_for_search: leads, 
                isLoading: false, 
                lead_board_id: board_id
            })

            this.getGroupIdsInBoard(board_id);

        }).catch(err => {
            console.log("err", err)
            this.setState({ isLoading: false})
        })

    }

    getGroupIdsInBoard = (board_id)  => {
        this.setState({
            board_group_ids: [],
        })
        request('GET', `/dashboard/get-board-group-ids/${board_id}`, {}, true).then(res => {
            const board_group_ids = res.data.data
            this.setState({
                board_group_ids
            })
        }).catch(err => {
            console.log("err", err)
        })
    }

    onChange = (e) => {
        const searchVal = e.target.value.toLowerCase();

        let selectedList = this.state.leads_for_search.filter((lead) => (
            
            lead.name.toLowerCase().includes(searchVal) ||
            // lead.open_inspection_address.toLowerCase().includes(searchVal) ||
            lead.lead_source.toLowerCase().includes(searchVal) ||
            lead.client_stage.toLowerCase().includes(searchVal) ||
            lead.our_stage.toLowerCase().includes(searchVal) ||
            lead.lead_creation_date.toLowerCase().includes(searchVal) ||
            lead.lead_close_date.toLowerCase().includes(searchVal) ||
            lead.estimated_loan_amount.toLowerCase().includes(searchVal) ||
            lead.actual_loan_amount.toLowerCase().includes(searchVal) ||
            // lead.commission_type.toLowerCase().includes(searchVal) ||
            lead.deal_notes.toLowerCase().includes(searchVal) || 
            lead.contacts.toLowerCase().includes(searchVal)

        ));

        this.setState({
            leads: selectedList
        })

    }

    propertyMatchFunction = (id) => {

        const lead_id = id;

        this.setState({
            property_match_details: [],
            matched_columns_con: [],
            showPropertyModal: true,
            isLoadingPropertyMatchDetails: true,
            isLoadingClientPreferences: true,
            cur_lead_id: lead_id
        })

        request('GET', `/dashboard/get-properties-board-data/${lead_id}`, {}, true).then(res => {
            
            // console.log("get-properties-board-data", res)
            const property_match_details = res.data.data.map((obj) => ({
                id: obj.id ?? "",
                name: obj.name ?? "",
                status: obj.status ?? "",
                property_address: obj.property_address ?? "",
                partners: obj.partners.toString() ?? "",
                property_type: obj.property_type ?? "",
                date_entered: obj.date_entered ?? "",
                no_of_bedrooms: obj.no_of_bedrooms ?? "",
                no_of_bathrooms: obj.no_of_bathrooms ?? "",
                no_of_storage: obj.no_of_storage ?? "",
                flat_land: obj.flat_land ?? "",
                building_age: obj.building_age ?? "",
                land_size: obj.land_size ?? "",
                internal_size: obj.internal_size ?? "",
                newly_renovated: obj.newly_renovated ?? "",
                walking_distance_to_schools: obj.walking_distance_to_schools ?? "",
                walking_distance_to_shopping: obj.walking_distance_to_shopping ?? "",
                development_potential: obj.development_potential ?? "",
                swimming_pool: obj.swimming_pool ?? "",
                price_range: obj.price_range ?? "",
            }));

            const property_match_columns = res.data.matched_columns.map(element => {
                for (const [key, value] of Object.entries(element)) {
                    var arr_con = {}
                    var key_property_name = key.split(' ').join('_')
                    var lwrcased_key_property_name = key_property_name.toLowerCase();
                    arr_con[lwrcased_key_property_name] = value
                    this.state.matched_columns_con.push(arr_con);  
                }  
            });

            this.setState({ 
                property_match_details, 
                matched_columns: property_match_columns, 
                isLoadingPropertyMatchDetails: false,
                statement: "Property Items that matched in your the client's reference"
            })

        }).catch(err => {
            console.log("err", err)
            this.setState({ isLoadingPropertyMatchDetails: false})
        })

        request('GET', `/dashboard/get-client-property-references/${lead_id}`, {}, true).then(res => {
            // console.log("get-client-property-references", res)       

            const board_id = res.data.board_id;
            const client_property_references = res.data.data.map((obj) => ({
                id: obj.id ?? "",
                name: obj.name ?? "",
                property_address: obj.property_address ?? "",
                contacts: obj.contacts.toString() ?? "",
                property_type: obj.property_type ?? "",
                date_entered: obj.date_entered ?? "",
                no_of_bedrooms: obj.no_of_bedrooms ?? "",
                no_of_bathrooms: obj.no_of_bathrooms ?? "",
                no_of_storage: obj.no_of_storage ?? "",
                flat_land: obj.flat_land ?? "",
                building_age: obj.building_age ?? "",
                land_size: obj.land_size ?? "",
                internal_size: obj.internal_size ?? "",
                newly_renovated: obj.newly_renovated ?? "",
                walking_distance_to_schools: obj.walking_distance_to_schools ?? "",
                walking_distance_to_shopping: obj.walking_distance_to_shopping ?? "",
                development_potential: obj.development_potential ?? "",
                swimming_pool: obj.swimming_pool ?? "",
                price_range: obj.price_range ?? "",
            }));

            this.setState({
                client_preferences: client_property_references,
                isLoadingClientPreferences: false,
                client_pref_board_id: board_id
            })

            if( board_id ) {
                this.getGroupIdsInBoard(board_id);
                this.getStatusColumnValues(board_id);
            }
           
        })

    }

    hidePropertyModal = () => {
        this.setState({
            showPropertyModal: false
        })
    }

    getTdColor = (val, unique_matched_columns, key_val) => {

        let bgcolor=''

        if(val){    

            let index = unique_matched_columns.filter((x) => {

                let obj = Object.keys(x)
                let key_name = obj[0];

                if(key_val == key_name){
                    return x[key_name].includes(val);
                }
                
            });

            // console.log(val + " index", index)
    
            if(index.length > 0 ){
                bgcolor = '#68f289'
            }

        }

        return bgcolor;

    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
        console.log("val ", value);
        console.log("accessor ", accessor);
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
    
        if (insertNewFilter) {
          filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filtered: filtered });
      };

    editLeadFuntion = (e) => {

        const lead_id = e;
        this.setState({
            showEditLeadModal: true,
            isLoadingEditModal: true,
            cur_lead_id: lead_id,
            showDivForm: "block",
            showSuccessProcessMessage: "none"
        })
        
        request('GET', `/dashboard/get-single-item/${lead_id}`, {}, true).then(res => {
            // console.log("res", res)

            const item_data = res.data.data;

            this.setState({
                cur_name: item_data.name,
                cur_open_inspection_address: item_data.open_inspection_address,
                cur_lead_source: item_data.lead_source,
                cur_client_stage: item_data.client_stage,
                cur_our_stage: item_data.our_stage,
                cur_lead_creation_date: item_data.lead_creation_date,
                cur_lead_close_date: item_data.lead_close_date,
                cur_estimated_loan_amount: item_data.estimated_loan_amount,
                cur_actual_loan_amount: item_data.actual_loan_amount,
                cur_commission_type: item_data.commission_type,
                cur_deal_notes: item_data.deal_notes,
                cur_contacts: item_data.contacts,
                isLoadingEditModal: false
            })

        }).catch(err => {
            console.log("err", err)
            this.setState({ isLoading: false})
        })

    }

    deleteLeadFuntion = (e) => {

        const lead_id = e;
        this.setState({
            showDeleteLeadModal: true,
            cur_item_id: lead_id,
            deleteMessage: "Are you sure you want to delete this lead?",
            item_name: "lead"
        })

    }

    hideEditLeadModal = () => {
        this.setState({
            showEditLeadModal: false
        })
    }

    hideDeleteLeadModal = () => {
        this.setState({
            showDeleteLeadModal: false
        })
    }

    handleOnChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmitEditedLead = async (e) =>  {
        e.preventDefault();

        this.setState({
            isLoadingEditModal: true
        })

        const data = {
            address: this.state.cur_name,
            lead_source: this.state.cur_lead_source,
            client_stage: this.state.cur_client_stage,
            our_stage: this.state.cur_our_stage,
            estimated_loan_amount: this.state.cur_estimated_loan_amount,
            actual_loan_amount: this.state.cur_actual_loan_amount,
            commission_type: this.state.cur_commission_type,
            deal_notes: this.state.cur_deal_notes,
            contacts: this.state.cur_contacts,

            // lead_creation_date: this.state.cur_lead_creation_date,
            // lead_close_date: this.state.cur_lead_close_date,
            // open_inspection_address: this.state.cur_open_inspection_address,
        }

        request('POST', `/dashboard/post-updated-item/${this.state.cur_lead_id}`, data, true).then(res => {
            this.setState({
                isLoadingEditModal: false,
                // showEditLeadModal: false
                successMessage: res.data.message,
                isLoading: true,
                showDivForm: "none",
                showSuccessProcessMessage: "block"
            })
    
            this.getMondayData();
        }).catch(err => {
            console.log("err", err)
            this.setState({ isLoading: false})
        })

    }

    handleDeleteLead = () => {

        const item_id = this.state.cur_item_id;
        this.setState({
            isLoadingDeleteModal: true
        })

        request('DELETE', `/dashboard/delete-monday-item/${item_id}`, {}, true).then(res => {
            this.setState({
                deleteMessage: "Item successfully deleted.",
                isLoadingDeleteModal: false,
                cancelOkButton: "Ok",
                displaySaveButton: "none"
            })

            if(this.state.item_name == "client_pref"){

                this.setState({
                    isLoadingPropertyMatchDetails: true,
                    isLoadingClientPreferences: true
                })
                this.propertyMatchFunction(this.state.cur_lead_id)

            }

            if(this.state.item_name == "lead"){

                this.setState({
                    isLoading: true,
                })
                this.getMondayData();
            }
            
        }).catch(err => {
            console.log("err", err)
            this.setState({ isLoading: false})
        })
    }

    addNewLead = () => {

        this.setState({
            showAddNewLeadModal: true,
            showDivForm: "block",
            showSuccessProcessMessage: "none",
            cur_actual_loan_amount: "",
            cur_client_stage: "",
            cur_contacts: "",
            cur_deal_notes: "",
            cur_estimated_loan_amount: "",
            cur_lead_source: "",
            cur_name: "",
            cur_our_stage: "",
        })
        
    }

    hideAddNewLeadModal = () => {
        this.setState({
            showAddNewLeadModal: false
        })
    }

    handleSubmitAddLead = (e) => {
        e.preventDefault();

        this.setState({
            isLoadingAddModal: true
        })

        const data = {
            address: this.state.cur_name,
            lead_source: this.state.cur_lead_source,
            client_stage: this.state.cur_client_stage,
            our_stage: this.state.cur_our_stage,
            estimated_loan_amount: this.state.cur_estimated_loan_amount,
            actual_loan_amount: this.state.cur_actual_loan_amount,
            deal_notes: this.state.cur_deal_notes,
            contacts: this.state.cur_contacts,
            board_id: this.state.lead_board_id,
            group_id: this.state.group_id,
            partner_id: this.context.user.user.item_id
            // open_inspection_address: this.state.cur_open_inspection_address,
            // lead_creation_date: this.state.cur_lead_creation_date,
            // lead_close_date: this.state.cur_lead_close_date,
            // commission_type: this.state.cur_commission_type,
        }

        request('POST', `/dashboard/add-new-item`, data, true).then(res => {

            this.setState({
                isLoadingAddModal: false,
                isLoading: true,
                successMessage: res.data.message,
                showDivForm: "none",
                showSuccessProcessMessage: "block"
            })
    
            this.getMondayData();
        }).catch(err => {
            console.log("err", err)
            this.setState({ isLoading: false})
        })

    }
    
    deleteClientPrefFuntion = (id) => {

        this.setState({
            cur_item_id: id,
            showDeleteLeadModal: true,
            deleteMessage: "Are you sure you want to delete this item?",
            item_name: "client_pref",
            displaySaveButton: "block",
            cancelOkButton: "Cancel"
        })

    }

    editClientPrefFuntion = (id) => {
        
        this.setState({
            cur_item_id: id,
            showEditClientPrefModal: true,
            isLoadingEditModal: true,
            showDivForm: "block",
            showSuccessProcessMessage: "none",
            displaySaveButton: "block",
            cancelOkButton: "Cancel",
            client_ref_modal_name: "Edit Client Reference",
            client_pref_action: "edit",
            displayClientPrefGroup: "none"
        })

        request('GET', `/dashboard/get-single-client-pref/${id}`, {}, true).then(res => {

            const item_data = res.data.data;
            
            this.setState({
                cur_item_name: item_data.name,
                cur_property_address: item_data.property_address,
                cur_contacts: item_data.contacts.toString(),
                cur_property_type: item_data.property_type,
                cur_date_entered: item_data.date_entered,
                cur_no_of_bedrooms: item_data.no_of_bedrooms,
                cur_no_of_bathrooms: item_data.no_of_bathrooms,
                cur_no_of_storage: item_data.no_of_storage,
                cur_flat_land: item_data.flat_land,
                cur_building_age: item_data.building_age,
                cur_land_size: item_data.land_size,
                cur_internal_size: item_data.internal_size,
                cur_newly_renovated: item_data.newly_renovated,
                cur_walking_distance_to_schools: item_data.walking_distance_to_schools,
                cur_walking_distance_to_shopping: item_data.walking_distance_to_shopping,
                cur_development_potential: item_data.development_potential,
                cur_swimming_pool: item_data.swimming_pool,
                cur_price_range: item_data.price_range,
                isLoadingEditModal: false,
            })

        }).catch(err => {
            console.log("err", err)
            this.setState({ isLoadingEditModal: false})
        })

    }

    getStatusColumnValues = (board_id) => {

        request('GET', `/dashboard/get-status-column-values/${board_id}`, {}, true).then(res => {
            // console.log("get-status-column-values", res)

            const building_age_column_values = Object.values(res.data.building_age_column_values); 
            const development_potential_column_values = Object.values(res.data.development_potential_column_values); 
            const flat_land_column_values = Object.values(res.data.flat_land_column_values); 
            const internal_size_column_values = Object.values(res.data.internal_size_column_values); 
            const land_size_column_values = Object.values(res.data.land_size_column_values); 
            const newly_renovated_column_values = Object.values(res.data.newly_renovated_column_values); 
            const no_of_bathrooms_column_values = Object.values(res.data.no_of_bathrooms_column_values); 
            const no_of_bedrooms_column_values = Object.values(res.data.no_of_bedrooms_column_values); 
            const no_of_storage_column_values = Object.values(res.data.no_of_storage_column_values); 
            const price_range_column_values = Object.values(res.data.price_range_column_values); 
            const property_type_column_values = Object.values(res.data.property_type_column_values); 
            const swimming_pool_column_values = Object.values(res.data.swimming_pool_column_values); 
            const walking_distance_to_schools_column_values = Object.values(res.data.walking_distance_to_schools_column_values); 
            const walking_distance_to_shopping_column_values = Object.values(res.data.walking_distance_to_shopping_column_values); 

            this.setState({
                building_age_column_values,
                development_potential_column_values,
                flat_land_column_values,
                internal_size_column_values,
                land_size_column_values, 
                newly_renovated_column_values,
                no_of_bathrooms_column_values,
                no_of_bedrooms_column_values,
                no_of_storage_column_values,
                price_range_column_values,
                property_type_column_values,
                swimming_pool_column_values,
                walking_distance_to_schools_column_values,
                walking_distance_to_shopping_column_values,            
            })

        }).catch(err => {
            console.log("err", err)
        })

    }

    hideEditClientPrefModal = () => {
        this.setState({
            showEditClientPrefModal: false,
        })
    }

    handleSubmitClientPrefModal = (e) => {
        e.preventDefault();

        const cur_item_id = this.state.cur_item_id;

        this.setState({
            isLoadingEditModal: true,
        })

        const data = {
            group_id: this.state.group_id,
            board_id: this.state.client_pref_board_id,
            name: this.state.cur_item_name,
            property_address: this.state.cur_property_address,
            contacts: this.state.cur_contacts,
            property_type: this.state.cur_property_type,
            date_entered: this.state.cur_date_entered,
            no_of_bedrooms: this.state.cur_no_of_bedrooms,
            no_of_bathrooms: this.state.cur_no_of_bathrooms,
            no_of_storage: this.state.cur_no_of_storage,
            flat_land: this.state.cur_flat_land,
            building_age: this.state.cur_building_age,
            land_size: this.state.cur_land_size,
            internal_size: this.state.cur_internal_size,
            newly_renovated: this.state.cur_newly_renovated,
            walking_distance_to_schools: this.state.cur_walking_distance_to_schools,
            walking_distance_to_shopping: this.state.cur_walking_distance_to_shopping,
            development_potential: this.state.cur_development_potential,
            swimming_pool: this.state.cur_swimming_pool,
            price_range: this.state.cur_price_range,
        }

        if(this.state.client_pref_action == "edit"){

            request('POST', `/dashboard/update-client-preference/${cur_item_id}`, data, true).then(res => {
                //console.log("update-client-preference", res)
                this.setState({
                    isLoadingEditModal: false,
                    successMessage: res.data.message,
                    showDivForm: "none",
                    showSuccessProcessMessage: "block",
                    displaySaveButton: "none",
                    cancelOkButton: "Ok",
                })

                this.propertyMatchFunction(this.state.cur_lead_id)
                
            }).catch(err => {
                console.log("err", err)
                this.setState({ isLoading: false})
            })

        }

        if(this.state.client_pref_action == "add"){

            request('POST', `/dashboard/add-new-client-preference/${cur_item_id}`, data, true).then(res => {
                console.log("add-client-preference", res)
                this.setState({
                    successMessage: res.data.message,
                    showDivForm: "none",
                    showSuccessProcessMessage: "block",
                    displaySaveButton: "none",
                    cancelOkButton: "Ok",
                }, () => {

                    const add_new_cp_id_data = {
                        newly_added_item_id: res.data.data.data.create_item.id,
                        board_id: this.state.lead_board_id,
                    }

                    request('POST', `/dashboard/add-new-client-pref-to-lead/${this.state.cur_lead_id}`, add_new_cp_id_data, true).then(res => {

                        console.log("add-new-client-pref-to-lead", res);

                        this.setState({
                            isLoadingEditModal: false,
                            isLoadingPropertyMatchDetails: true,
                            isLoadingClientPreferences: true, 
                        })

                        this.propertyMatchFunction(this.state.cur_lead_id)
                        
                    }).catch(err => {
                        console.log("err", err)
                        // this.setState({ isLoading: false})
                    })
                })
    
            }).catch(err => {
                console.log("err", err)
                this.setState({ isLoading: false})
            })
            
        }
     
       
    }

    addNewClientPref  = () => {

        this.setState({
            client_pref_action: "add",
            cancelOkButton: "Cancel",
            showDivForm: "block",
            showSuccessProcessMessage: "none",
            displaySaveButton: "block",
            displayClientPrefGroup: "block",
            showEditClientPrefModal: true,
            cancelOkButton: "Cancel",
            cur_item_name: "",
            cur_property_address: "",
            cur_contacts: "",
            cur_property_type: "",
            cur_date_entered: "",
            cur_no_of_bedrooms: "",
            cur_no_of_bathrooms: "",
            cur_no_of_storage: "",
            cur_flat_land: "",
            cur_building_age: "",
            cur_land_size: "",
            cur_internal_size: "",
            cur_newly_renovated: "",
            cur_walking_distance_to_schools: "",
            cur_walking_distance_to_shopping: "",
            cur_development_potential: "",
            cur_swimming_pool: "",
            cur_price_range: "",
            client_ref_modal_name: "Add New Client Reference"
        })
        
    }

    render() {

        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }

        const unique_matched_columns = this.state.matched_columns_con.filter(onlyUnique);  

        const lead_sources = [
            'Existing partner clients',
            'Partner direct',
            'Recurring',
            'Social Media',
            'Expo',
            'Open inspection',
            'Cold call',
            'Existing non partner client'
        ];

        const client_stages = [
            'Idea',
            'Info collecting',
            'Active looking',
            'Ready to bid',
            'Proceed with us',
            'Gone with others'
        ];

        const our_stages = [
            'Approved',
            'Application Lodged',
            'Collecting docs',
            'Doc received',
            'Prospecting',
            'Met',
            'Settled'
        ];  

        const title_header =  [
            "Name",
            "Property Address",
            "Property Type",
            "Contacts",
            "Date Entered",
            "No of Bedrooms",
            "No of Bathrooms",
            "No of Storage",
            "Flat Land",
            "Building Age",
            "Land Size",
            "Internal Size",
            "Newly Renovated", 
            "Walking Distance to School",
            "Walking Distance to Shopping",
            "Development Potential",
            "Swimming Pool",
            "Price Range"	
        ];

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        })
       
        const customFilter = ({ fieldName, filter, onChange }) => {

            return (
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
              > 
                <option value="all">Show All</option>
                {this.state.leads
                  .map(item => item[fieldName])
                  .filter((item, i, s) => s.lastIndexOf(item) == i)
                  .map(function (value) {
                    // console.log('renderItem: ', value);
                    if(value){                       

                        /* Get Lead sources */
                        // if(!lead_sources.includes(value) && fieldName == "lead_source"){
                        //     lead_sources.push(value)
                        // }

                        /* Get Client Stages */
                        // if(!client_stages.includes(value) && fieldName == "client_stage"){
                        //     client_stages.push(value)
                        // }

                        /* Get Our Stages */
                        // if(!our_stages.includes(value) && fieldName == "our_stage"){
                        //     our_stages.push(value)
                        // }
                        
                        return (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          );
                    }
                  })}
              </select>
            );
        }
         
        const columns = [
            {
                Header: () => (<span className='fw-bold'>Action</span>),
                id: "action",
                accessor: "action",
                width: 100,
                fixed: 'left',
                style: {background: '#fff'},
                Cell: row => {
                    return <React.Fragment>
                            <span className="span-center"><i className="nc-icon nc-simple-remove center-icon" onClick={() => this.deleteLeadFuntion(row.original && row.original.id)}/></span>
                            <span className="span-centers"><i className="nc-icon nc-ruler-pencil center-icon" onClick={() => this.editLeadFuntion(row.original && row.original.id) }/></span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Property Match</span>),
                id: "property_match",
                accessor: "property_match",
                width: 200,
                fixed: 'left',
                style: {background: '#fff'},
                Cell: row => {
                    return <React.Fragment>
                            <span className="span-center"><button className='btn btn-primary center-button' value={row.original && row.original.id} onClick={() => this.propertyMatchFunction(row.original && row.original.id)}>Show Property</button></span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Client Name</span>),
                id: "contacts",
                accessor: "contacts",
                fixed: "left",
                width: 300,
                style: {background: '#fff', opacity: 0.7},
                Cell: row => {
                    return <React.Fragment>
                            {/* <span className="center"> { row.original && row.original.name}</span> */}
                            <span className="center fw-bold"><mark style={{"background":"white"}}>{row.original && row.original.contacts.toUpperCase()}</mark></span>

                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Address</span>),
                id: "name",
                accessor: "name",
                width: 350,
                // filterable: true,
                Cell: row => {
                    return <React.Fragment>
                            {/* <span className="center"> { row.original && row.original.open_inspection_address}</span> */}
                            <span className="center"> { row.original && row.original.name}</span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Lead Source</span>),
                id: "lead_source",
                accessor: "lead_source",
                width: 200,
                filterable: true,
                filterMethod: (filter, row) => {

                    if(filter.value == 'all'){
                        // console.log("filter", filter)
                        // console.log("row", row)
                        return row;
                    } else {
                        return row[filter.id] === filter.value;
                    }
                    
                },
                Filter: ({ filter, onChange }) =>
                customFilter({ fieldName:'lead_source', filter, onChange }),
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && row.original.lead_source}</span>
                        </React.Fragment>
                },
            },
            
            {
                Header: () => (<span className='fw-bold'>Client Stage</span>),
                id: "client_stage",
                accessor: "client_stage",
                width: 200,
                filterable: true,
                filterMethod: (filter, row) => {
                    if(filter.value == 'all'){
                        // console.log("filter", filter)
                        // console.log("row", row)
                        return row;
                    } else {
                        return row[filter.id] === filter.value;
                    }
                },
                Filter: ({ filter, onChange }) =>
                customFilter({ fieldName:'client_stage', filter, onChange }),
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && row.original.client_stage}</span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Stage</span>),
                id: "our_stage",
                accessor: "our_stage",
                width: 200,
                filterable: true,
                filterMethod: (filter, row) => {
                    if(filter.value == 'all'){
                        // console.log("filter", filter)
                        // console.log("row", row)
                        return row;
                    } else {
                        return row[filter.id] === filter.value;
                    }
                },
                Filter: ({ filter, onChange }) =>
                customFilter({ fieldName:'our_stage', filter, onChange }),
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && row.original.our_stage}</span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Deal Notes</span>),
                id: "deal_notes",
                accessor: "deal_notes",
                width: 500,
                // filterable: true,
                Cell: row => {
                    return <React.Fragment>
                                <textarea rows={5} cols={20} className="form-control" value={row.original && row.original.deal_notes}>
                                </textarea>
                            </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Lead Creation Date</span>),
                id: "lead_creation_date",
                accessor: "lead_creation_date",
                width: 200,
                Cell: row => {
                    return <React.Fragment>
                            {/* <span className="center"> { row.original && moment(row.original.lead_creation_date).format('DD-MMMM-YYYY')}</span> */}
                            <span className="center"> { row.original && (row.original.lead_creation_date != "" ? row.original.lead_creation_date : "")}</span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Close Date</span>),
                id: "lead_close_date",
                accessor: "lead_close_date",
                width: 200,
                Cell: row => {
                    return <React.Fragment>
                            {/* <span className="center"> { row.original && (row.original.lead_close_date != "" ? moment(row.original.lead_close_date).format('DD-MMMM-YYYY') : "")}</span> */}
                            <span className="center"> { row.original && (row.original.lead_close_date != "" ? row.original.lead_close_date : "")}</span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Estimate Loan Amount</span>),
                id: "estimated_loan_amount",
                accessor: "estimated_loan_amount",
                width: 250,
                // filterable: true,
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && ( formatter.format(row.original.estimated_loan_amount) != NaN ? formatter.format(row.original.estimated_loan_amount) : "")}</span>
                        </React.Fragment>
                },
            },
            {
                Header: () => (<span className='fw-bold'>Actual Loan Amount</span>),
                id: "actual_loan_amount",
                accessor: "actual_loan_amount",
                width: 250,
                // filterable: true,
                Cell: row => {
                    return <React.Fragment>
                            <span className="center"> { row.original && (formatter.format(row.original.estimated_loan_amount) != NaN ? formatter.format(row.original.actual_loan_amount) : "")}</span>
                        </React.Fragment>
                },
            },
            // {
            //     Header: () => (<span className='fw-bold'>Commmission Type</span>),
            //     id: "commission_type",
            //     accessor: "commission_type",
            //     width: 250,
            //     Cell: row => {
            //         return <React.Fragment>
            //                 <span className="center"> { row.original && row.original.commission_type}</span>
            //             </React.Fragment>
            //     },
            // }
        ]

        return (
            <div className="row">
                <div className="container">
                    <div className="row height d-flex justify-content-center align-items-center">
                        <div className="col-lg-8 col-md-8">
                            <div className="search"> <i className="fa fa-search" /> <input type="text" name="searchData" onChange={this.onChange} className="form-control" placeholder="Search . . ." /> 
                            {/* <button className="btn btn-primary" onClick={this.searchFunction(this.state.searchData)}>Search</button>  */}
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2">
                          
                        </div>
                    </div>
                </div>

                {/* Client Preferences Modal*/}
			    <Modal 
                    size="lg" 
                    show={this.state.showEditClientPrefModal} 
                    onHide={this.hideEditClientPrefModal}
                    backdrop="static"
                >
				    <Modal.Header className="fill-color-modal" closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            {this.state.client_ref_modal_name}
                        </Modal.Title>
                    </Modal.Header>
					{/* <form onSubmit={this.handleSubmitClientPrefModal}> */}
						<Modal.Body>

							{
								this.state.isLoadingEditModal ? 
                                <center>
                                    <Loader
                                        type="TailSpin"
                                        color="#000000"
                                        height={100}
                                        width={100}
                                    />
                                </center> : (  
                                    <React.Fragment>
                                        <div class="card" style={{'display': this.state.showDivForm}}>
                                            <div class="card-body">
                                                <div class="form-group" style={{'display': this.state.displayClientPrefGroup}}>
                                                    <p className='fw-bold'>Group: </p>
                                                        <select 
                                                            class="form-select" 
                                                            value={this.state.group_id} 
                                                            name="group_id"
                                                            onChange={this.handleOnChangeValue}                                                
                                                        >
                                                            <option value="">-Select-</option>
                                                            {
                                                                this.state.board_group_ids.map(group => {
                                                                    return <option value={group.id}>{group.title}</option>
                                                                })
                                                            }
                                                        
                                                        </select>
                                                </div>
                                                <div class="form-group">
                                                    <p className='fw-bold'>Item Name: </p>
                                                    <input type="text" className='form-control' name="cur_item_name" onChange={this.handleOnChangeValue} value={this.state.cur_item_name} />
                                                </div>
                                                <div class="form-group">
                                                    <p className='fw-bold'>Property Address: </p>
                                                    <input type="text" className='form-control' name="cur_property_address" onChange={this.handleOnChangeValue} value={this.state.cur_property_address} />
                                                </div>
                                                <div class="form-group">
                                                    <p className='fw-bold'>Contacts: </p>
                                                    <select 
                                                        class="form-select" 
                                                        value={this.state.cur_contacts} 
                                                        name="cur_contacts"
                                                        onChange={this.handleOnChangeValue}                                                
                                                    >
                                                        <option value="">-Select-</option>
                                                        {
                                                            this.state.contacts.map(val => {
                                                                return <option value={val}>{val}</option>
                                                            })
                                                        }
                                                    
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <p className='fw-bold'>Property Type: </p>
                                                    <select 
                                                        class="form-select" 
                                                        value={this.state.cur_property_type} 
                                                        name="cur_property_type"
                                                        onChange={this.handleOnChangeValue}                                                
                                                    >
                                                        <option value="">-Select-</option>
                                                        {
                                                            this.state.property_type_column_values.map(val => {
                                                                return <option value={val}>{val}</option>
                                                            })
                                                        }
                                                    
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <p className='fw-bold'>Date Entered: </p>
                                                    <input type="date" className='form-control' name="cur_date_entered" value={this.state.cur_date_entered}  onChange={this.handleOnChangeValue}/>
                                                </div>
                                                <div class="form-group">
                                                    <p className='fw-bold'>No of Bedrooms: </p>
                                                    <select 
                                                        class="form-select" 
                                                        value={this.state.cur_no_of_bedrooms} 
                                                        name="cur_no_of_bedrooms"
                                                        onChange={this.handleOnChangeValue}                                                
                                                    >
                                                        <option value="">-Select-</option>
                                                        {
                                                            this.state.no_of_bedrooms_column_values.map(val => {
                                                                return <option value={val}>{val}</option>
                                                            })
                                                        }
                                                    
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <p className='fw-bold'>No of Bathrooms: </p>
                                                    <select 
                                                        class="form-select" 
                                                        value={this.state.cur_no_of_bathrooms} 
                                                        name="cur_no_of_bathrooms"
                                                        onChange={this.handleOnChangeValue}                                                
                                                    >
                                                        <option value="">-Select-</option>
                                                        {
                                                            this.state.no_of_bathrooms_column_values.map(val => {
                                                                return <option value={val}>{val}</option>
                                                            })
                                                        }
                                                    
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <p className='fw-bold'>No of Storage: </p>
                                                    <select 
                                                        class="form-select" 
                                                        value={this.state.cur_no_of_storage} 
                                                        name="cur_no_of_storage"
                                                        onChange={this.handleOnChangeValue}                                                
                                                    >
                                                        <option value="">-Select-</option>
                                                        {
                                                            this.state.no_of_storage_column_values.map(val => {
                                                                return <option value={val}>{val}</option>
                                                            })
                                                        }
                                                    
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <p className='fw-bold'>Flat Land: </p>
                                                    <select 
                                                        class="form-select" 
                                                        value={this.state.cur_flat_land} 
                                                        name="cur_flat_land"
                                                        onChange={this.handleOnChangeValue}                                                
                                                    >
                                                        <option value="">-Select-</option>
                                                        {
                                                            this.state.flat_land_column_values.map(val => {
                                                                return <option value={val}>{val}</option>
                                                            })
                                                        }
                                                    
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <p className='fw-bold'>Building Age: </p>
                                                    <select 
                                                        class="form-select" 
                                                        value={this.state.cur_building_age} 
                                                        name="cur_building_age"
                                                        onChange={this.handleOnChangeValue}                                                
                                                    >
                                                        <option value="">-Select-</option>
                                                        {
                                                            this.state.building_age_column_values.map(val => {
                                                                return <option value={val}>{val}</option>
                                                            })
                                                        }
                                                    
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <p className='fw-bold'>Land Size: </p>
                                                    <select 
                                                        class="form-select" 
                                                        value={this.state.cur_land_size} 
                                                        name="cur_land_size"
                                                        onChange={this.handleOnChangeValue}                                                
                                                    >
                                                        <option value="">-Select-</option>
                                                        {
                                                            this.state.land_size_column_values.map(val => {
                                                                return <option value={val}>{val}</option>
                                                            })
                                                        }
                                                    
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <p className='fw-bold'>Internal Size: </p>
                                                    <select 
                                                        class="form-select" 
                                                        value={this.state.cur_internal_size} 
                                                        name="cur_internal_size"
                                                        onChange={this.handleOnChangeValue}                                                
                                                    >
                                                        <option value="">-Select-</option>
                                                        {
                                                            this.state.internal_size_column_values.map(val => {
                                                                return <option value={val}>{val}</option>
                                                            })
                                                        }
                                                    
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <p className='fw-bold'>Newly Renovated: </p>
                                                    <select 
                                                        class="form-select" 
                                                        value={this.state.cur_newly_renovated} 
                                                        name="cur_newly_renovated"
                                                        onChange={this.handleOnChangeValue}                                                
                                                    >
                                                        <option value="">-Select-</option>
                                                        {
                                                            this.state.newly_renovated_column_values.map(val => {
                                                                return <option value={val}>{val}</option>
                                                            })
                                                        }
                                                    
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <p className='fw-bold'>Walking Distance to Schools: </p>
                                                    <select 
                                                        class="form-select" 
                                                        value={this.state.cur_walking_distance_to_schools} 
                                                        name="cur_walking_distance_to_schools"
                                                        onChange={this.handleOnChangeValue}                                                
                                                    >
                                                        <option value="">-Select-</option>
                                                        {
                                                            this.state.walking_distance_to_schools_column_values.map(val => {
                                                                return <option value={val}>{val}</option>
                                                            })
                                                        }
                                                    
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <p className='fw-bold'>Walking Distance to Shopping: </p>
                                                    <select 
                                                        class="form-select" 
                                                        value={this.state.cur_walking_distance_to_shopping} 
                                                        name="cur_walking_distance_to_shopping"
                                                        onChange={this.handleOnChangeValue}                                                
                                                    >
                                                        <option value="">-Select-</option>
                                                        {
                                                            this.state.walking_distance_to_shopping_column_values.map(val => {
                                                                return <option value={val}>{val}</option>
                                                            })
                                                        }
                                                    
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <p className='fw-bold'>Development Potential: </p>
                                                    <select 
                                                        class="form-select" 
                                                        value={this.state.cur_development_potential} 
                                                        name="cur_development_potential"
                                                        onChange={this.handleOnChangeValue}                                                
                                                    >
                                                        <option value="">-Select-</option>
                                                        {
                                                            this.state.development_potential_column_values.map(val => {
                                                                return <option value={val}>{val}</option>
                                                            })
                                                        }
                                                    
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <p className='fw-bold'>Swimming Pool: </p>
                                                    <select 
                                                        class="form-select" 
                                                        value={this.state.cur_swimming_pool} 
                                                        name="cur_swimming_pool"
                                                        onChange={this.handleOnChangeValue}                                                
                                                    >
                                                        <option value="">-Select-</option>
                                                        {
                                                            this.state.swimming_pool_column_values.map(val => {
                                                                return <option value={val}>{val}</option>
                                                            })
                                                        }
                                                    
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <p className='fw-bold'>Price Range: </p>
                                                    <select 
                                                        class="form-select" 
                                                        value={this.state.cur_price_range} 
                                                        name="cur_price_range"
                                                        onChange={this.handleOnChangeValue}                                                
                                                    >
                                                        <option value="">-Select-</option>
                                                        {
                                                            this.state.price_range_column_values.map(val => {
                                                                return <option value={val}>{val}</option>
                                                            })
                                                        }
                                                    
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <center><p style={{"display": this.state.showSuccessProcessMessage}}>{this.state.successMessage}</p></center>
                                    </React.Fragment> 
                                
                                )
							}
							
						</Modal.Body>
						<Modal.Footer className="fill-color-modal">
							<input type="submit" className="btn btn-primary" value="Save" onClick={this.handleSubmitClientPrefModal} style={{"display": this.state.displaySaveButton}}/>
							<input type="submit" className="btn btn-secondary" value={this.state.cancelOkButton} onClick={this.hideEditClientPrefModal}/>
						</Modal.Footer>
                    {/* </form> */}
                </Modal>

                {/* Add New Lead Modal */}
                <Modal 
                    size="lg" 
                    show={this.state.showAddNewLeadModal} 
                    onHide={this.hideAddNewLeadModal}
                    // aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header className="fill-color-modal" closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Add New Lead
                        </Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.handleSubmitAddLead}>
                        <Modal.Body>

                            {
                                this.state.isLoadingAddModal ? 
                                    <center>
                                        <Loader
                                            type="TailSpin"
                                            color="#000000"
                                            height={100}
                                            width={100}
                                        />
                                    </center> : (  
                                        <React.Fragment>
                                            <div class="card" style={{'display': this.state.showDivForm}}>
                                                <div class="card-body">
                                                    <div class="form-group">
                                                        <p className='fw-bold'>Group: </p>
                                                            <select 
                                                                class="form-select" 
                                                                value={this.state.group_id} 
                                                                name="group_id"
                                                                onChange={this.handleOnChangeValue}                                                
                                                            >
                                                                <option value="">-Select-</option>
                                                                {
                                                                    this.state.board_group_ids.map(group => {
                                                                        return <option value={group.id}>{group.title}</option>
                                                                    })
                                                                }
                                                            
                                                            </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <p className='fw-bold'>Client Name: </p>
                                                            <select 
                                                                class="form-select" 
                                                                value={this.state.cur_contacts} 
                                                                name="cur_contacts"
                                                                onChange={this.handleOnChangeValue}                                                
                                                                aria-label="Default select example"
                                                            >
                                                                <option value="">-Select-</option>
                                                                {
                                                                    this.state.contacts.map(contact => {
                                                                        return <option value={contact}>{contact}</option>
                                                                    })
                                                                }
                                                            
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <p className='fw-bold'>Address: </p>
                                                        <input type="text" className='form-control' name="cur_name" onChange={this.handleOnChangeValue} value={this.state.cur_name} />
                                                    </div>
                                                    <div class="form-group">
                                                        <p className='fw-bold'>Lead Source: </p>
                                                        <select 
                                                            class="form-select" 
                                                            value={this.state.cur_lead_source} 
                                                            name="cur_lead_source"
                                                            onChange={this.handleOnChangeValue}                                                
                                                            aria-label="Default select example"
                                                        >
                                                            <option value="">-Select-</option>
                                                            {
                                                                lead_sources.map(val => {
                                                                    return <option value={val}>{val}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <p className='fw-bold'>Client Stage: </p>
                                                        <select 
                                                            class="form-select" 
                                                            value={this.state.cur_client_stage} 
                                                            name="cur_client_stage"
                                                            onChange={this.handleOnChangeValue}                                                
                                                            aria-label="Default select example"
                                                        >
                                                            <option value="">-Select-</option>
                                                            {
                                                                client_stages.map(val => {
                                                                    return <option value={val}>{val}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <p className='fw-bold'>Stage: </p>
                                                        <select 
                                                            class="form-select" 
                                                            value={this.state.cur_our_stage} 
                                                            name="cur_our_stage"
                                                            onChange={this.handleOnChangeValue}                                                
                                                            aria-label="Default select example"
                                                        >
                                                            <option value="">-Select-</option>
                                                            {
                                                                our_stages.map(val => {
                                                                    return <option value={val}>{val}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <p className='fw-bold'>Deal Notes: </p>
                                                        <textarea rows={5} cols={20} className="form-control" value={this.state.cur_deal_notes} name="cur_deal_notes" onChange={this.handleOnChangeValue}>
                                                        </textarea>
                                                    </div>
                                                    {/* <div class="form-group">
                                                        <p className='fw-bold'>Lead Creation Date: </p>
                                                        <input type="date" className='form-control' name="cur_lead_creation_date" value={this.state.cur_lead_creation_date} onChange={this.handleOnChangeValue}/>
                                                    </div>
                                                    <div class="form-group">
                                                        <p className='fw-bold'>Close Date: </p>
                                                        <input type="date" className='form-control' name="cur_lead_close_date" onChange={this.handleOnChangeValue} value={this.state.cur_lead_close_date} />
                                                    </div> */}
                                                    <div class="form-group">
                                                        <p className='fw-bold'>Estimate Loan Amount: </p>
                                                        <input type="number" className='form-control' name="cur_estimated_loan_amount" onChange={this.handleOnChangeValue} value={this.state.cur_estimated_loan_amount} />
                                                    </div>
                                                    <div class="form-group">
                                                        <p className='fw-bold'>Actual Loan Amount: </p>
                                                        <input type="number" className='form-control' name="cur_actual_loan_amount" onChange={this.handleOnChangeValue} value={this.state.cur_actual_loan_amount} />
                                                    </div>
                                                </div>
                                            </div>
                                            <center><p style={{"display": this.state.showSuccessProcessMessage}}>{this.state.successMessage}</p></center>
                                        </React.Fragment> 
                                        
                                    )
                            }
                            
                        </Modal.Body>
                        <Modal.Footer className="fill-color-modal">
                            <input type="submit" className="btn btn-primary" value="Save"/>
                            <input type="submit" className="btn btn-secondary" value="Cancel" onClick={this.hideAddNewLeadModal}/>
                        </Modal.Footer>
                    </form>
                </Modal>
                
                {/*  Delete Lead Modal */}
                <Modal show={this.state.showDeleteLeadModal} onHide={this.hideDeleteLeadModal}>
                    <Modal.Header className="fill-color-modal" closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.isLoadingDeleteModal ? 
                                <center>
                                    <Loader
                                        type="TailSpin"
                                        color="#000000"
                                        height={100}
                                        width={100}
                                    />
                                </center>
                            :  ( <center> {this.state.deleteMessage} </center> )

                        }
                        
                    </Modal.Body>
                    <Modal.Footer className="fill-color-modal">
                        <input type="submit" className="btn btn-danger" value="Delete" style={{"display": this.state.displaySaveButton }} onClick={this.handleDeleteLead}/>
                        <input type="submit" className="btn btn-secondary" value={this.state.cancelOkButton} onClick={this.hideDeleteLeadModal}/>
                    </Modal.Footer>
                </Modal>

                {/* Edit Lead Modal */}
                
                <Modal
                    size="lg"
                    show={this.state.showEditLeadModal}
                    onHide={this.hideEditLeadModal}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton className="fill-color-modal">
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Edit Lead
                        </Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.handleSubmitEditedLead}>
                        <Modal.Body>
                        {
                            this.state.isLoadingEditModal ? 
                                <center>
                                    <Loader
                                        type="TailSpin"
                                        color="#000000"
                                        height={100}
                                        width={100}
                                    />
                                </center>
                            : (
                                <React.Fragment>

                                    <div class="card" style={{"display": this.state.showDivForm}}>
                                        <div class="card-body">
                                            <div class="form-group">
                                                <p className='fw-bold'>Client Name: </p>
                                                    <select 
                                                        class="form-select" 
                                                        value={this.state.cur_contacts} 
                                                        name="cur_contacts"
                                                        onChange={this.handleOnChangeValue}                                                
                                                        aria-label="Default select example"
                                                    >
                                                        <option value="">-Select-</option>
                                                        {
                                                            this.state.contacts.map(contact => {
                                                                return <option value={contact}>{contact}</option>
                                                            })
                                                        }
                                                    
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <p className='fw-bold'>Address: </p>
                                                <input type="text" className='form-control' name="cur_name" onChange={this.handleOnChangeValue} value={this.state.cur_name} />
                                            </div>
                                            <div class="form-group">
                                                <p className='fw-bold'>Lead Source: </p>
                                                <select 
                                                    class="form-select" 
                                                    value={this.state.cur_lead_source} 
                                                    name="cur_lead_source"
                                                    onChange={this.handleOnChangeValue}                                                
                                                    aria-label="Default select example"
                                                >
                                                    <option value="">-Select-</option>
                                                    {
                                                        lead_sources.map(val => {
                                                            return <option value={val}>{val}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <p className='fw-bold'>Client Stage: </p>
                                                <select 
                                                    class="form-select" 
                                                    value={this.state.cur_client_stage} 
                                                    name="cur_client_stage"
                                                    onChange={this.handleOnChangeValue}                                                
                                                    aria-label="Default select example"
                                                >
                                                    <option value="">-Select-</option>
                                                    {
                                                        client_stages.map(val => {
                                                            return <option value={val}>{val}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <p className='fw-bold'>Stage: </p>
                                                <select 
                                                    class="form-select" 
                                                    value={this.state.cur_our_stage} 
                                                    name="cur_our_stage"
                                                    onChange={this.handleOnChangeValue}                                                
                                                    aria-label="Default select example"
                                                >
                                                    <option value="">-Select-</option>
                                                    {
                                                        our_stages.map(val => {
                                                            return <option value={val}>{val}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <p className='fw-bold'>Deal Notes: </p>
                                                <textarea rows={5} cols={20} className="form-control" value={this.state.cur_deal_notes} name="cur_deal_notes" onChange={this.handleOnChangeValue}>
                                                </textarea>
                                            </div>
                                            {/* <div class="form-group">
                                                <p className='fw-bold'>Lead Creation Date: </p>
                                                <input type="date" className='form-control' name="cur_lead_creation_date" value={this.state.cur_lead_creation_date} onChange={this.handleOnChangeValue}/>
                                            </div>
                                            <div class="form-group">
                                                <p className='fw-bold'>Close Date: </p>
                                                <input type="date" className='form-control' name="cur_lead_close_date" onChange={this.handleOnChangeValue} value={this.state.cur_lead_close_date} />
                                            </div> */}
                                            <div class="form-group">
                                                <p className='fw-bold'>Estimate Loan Amount: </p>
                                                <input type="number" className='form-control' name="cur_estimated_loan_amount" onChange={this.handleOnChangeValue} value={this.state.cur_estimated_loan_amount} />
                                            </div>
                                            <div class="form-group">
                                                <p className='fw-bold'>Actual Loan Amount: </p>
                                                <input type="number" className='form-control' name="cur_actual_loan_amount" onChange={this.handleOnChangeValue} value={this.state.cur_actual_loan_amount} />
                                            </div>
                                        </div>
                                    </div>
                                    <center><p style={{"display": this.state.showSuccessProcessMessage}}>{this.state.successMessage}</p></center>
                                </React.Fragment>

                                
                            )
                        }  
                        </Modal.Body>
                        <Modal.Footer className="fill-color-modal">
                            <input type="submit" className="btn btn-primary" value="Save"/>
                            <input type="submit" className="btn btn-secondary" value="Cancel" onClick={this.hideEditLeadModal} />
                        </Modal.Footer>
                    </form>
                </Modal>

                {/* Property Match Modal */}
                <Modal
                    size="xl"
                    fullscreen={true}
                    show={this.state.showPropertyModal}
                    onHide={this.hidePropertyModal}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                       Property Match
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            // this.state.isLoadingPropertyMatchDetails ? 
                            this.state.isLoadingClientPreferences || this.state.isLoadingPropertyMatchDetails ?
                                <center>
                                    <Loader
                                        type="TailSpin"
                                        color="#000000"
                                        height={100}
                                        width={100}
                                        // style={{"margin-right" : "auto", "margin-left" : "auto"}}
                                    />
                                </center>
                            : (
                                <React.Fragment>
                                    <div className="m-5">
                                        <div className="row">
                                            <React.Fragment>
                                                {
                                                    this.state.client_preferences.length > 0 ? 
                                                        <table className='custom-table'>
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan={19} bgcolor="#66c4de" className='pd-30'>
                                                                        <div className='row'>
                                                                            <div className='col-lg-10'>
                                                                                <center>CLIENT PREFERENCES</center>
                                                                            </div>
                                                                            <div className='col-lg-2'>
                                                                                <button className="btn btn-primary btn-add-lead m-0" onClick={this.addNewClientPref}>
                                                                                    <i className="nc-icon nc-simple-add" />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Actions</th>
                                                                    {
                                                                        title_header.map((obj) => {
                                                                            return <th>{obj}</th>
                                                                        })
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {   
                
                                                                    this.state.client_preferences && this.state.client_preferences.map((val, key) =>  {
                                                                        return <React.Fragment>
                                                                            <tr>
                                                                                <td>
                                                                                    <span className="span-center"><i className="nc-icon nc-simple-remove center-icon" onClick={() => this.deleteClientPrefFuntion(val && val.id)}/></span>
                                                                                    <span className="span-centers"><i className="nc-icon nc-ruler-pencil center-icon" onClick={() => this.editClientPrefFuntion(val && val.id) }/></span>
                                                                                </td>
                                                                                <td>{val.name}</td>
                                                                                {/* <td> {val.status}</td> */}
                                                                                <td>{val.property_address}</td>
                                                                                <td>{val.property_type}</td>
                                                                                <td>{val.contacts}</td>
                                                                                <td>{val.date_entered && moment(val.date_entered).format('DD-MMMM-YYYY')}</td>
                                                                                <td>{val.no_of_bedrooms}</td>
                                                                                <td>{val.no_of_bathrooms}</td>
                                                                                <td>{val.no_of_storage}</td>
                                                                                <td>{val.flat_land}</td>
                                                                                <td>{val.building_age}</td>
                                                                                <td>{val.land_size}</td>
                                                                                <td>{val.internal_size}</td>
                                                                                <td>{val.newly_renovated}</td>
                                                                                <td>{val.walking_distance_to_schools}</td>
                                                                                <td>{val.walking_distance_to_shopping}</td>
                                                                                <td>{val.development_potential}</td>
                                                                                <td>{val.swimming_pool}</td>
                                                                                <td>{val.price_range}</td>
                                                                            </tr>
                                                                        </React.Fragment>
                                                                    
                                                                    }) 
                                                                }
                                                                
                                                            </tbody>
                                                        </table>
                                                     : (
                                                        <div className="col-lg-12 col-md-12 col-sm-12 alert alert-info">
                                                            <div className='row'>
                                                                <div className='col-lg-10'>
                                                                <p className='fw-bold text-center'>No client reference linked in this lead.</p>
                                                                </div>
                                                                <div className='col-lg-2'>
                                                                    <button className="btn btn-primary btn-add-lead m-0" onClick={this.addNewClientPref}>
                                                                        <i className="nc-icon nc-simple-add" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </React.Fragment>
                                                {
                                                    this.state.property_match_details.length > 0 ? (

                                                        <React.Fragment>
                                                            <p className='m-5 fw-bold'></p>
                                                            <table className='custom-table'>
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan={19} bgcolor="#66c4de" className='pd-30'><center>PROPERTY MATCH</center></th>
                                                                    </tr>
                                                                    <tr>
                                                                        {
                                                                            title_header.map((obj) => {
                                                                                return <th>{obj}</th>
                                                                            })
                                                                        }
                                                                    
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    
                                                                    {   
                
                                                                        this.state.property_match_details && this.state.property_match_details.map((val, key) =>  {


                                                                            var name_color = "";
                                                                            var property_address_color = "";
                                                                            var property_type_color = "";
                                                                            var no_of_bedrooms_color = "";
                                                                            var no_of_bathrooms_color = "";
                                                                            var no_of_storage_color = "";
                                                                            var flat_land_color = "";
                                                                            var building_age_color = "";
                                                                            var land_size_color = "";
                                                                            var internal_size_color = "";
                                                                            var newly_renovated_color = "";
                                                                            var walking_distance_to_schools_color = "";
                                                                            var walking_distance_to_shoppings_color = "";
                                                                            var development_potential_color = "";
                                                                            var swimming_pool_color = "";
                                                                            var price_range_color = "";
                                                                            
                                                                            name_color = this.getTdColor(val.name, unique_matched_columns, 'name');
                                                                            property_address_color = this.getTdColor(val.property_address, unique_matched_columns, "property_address");
                                                                            property_type_color = this.getTdColor(val.property_type, unique_matched_columns,"property_type");
                                                                            no_of_bedrooms_color = this.getTdColor(val.no_of_bedrooms, unique_matched_columns, "no_of_bedrooms");
                                                                            no_of_bathrooms_color = this.getTdColor(val.no_of_bathrooms, unique_matched_columns, "no_of_bathrooms");
                                                                            no_of_storage_color = this.getTdColor(val.no_of_storage, unique_matched_columns, "no_of_storage");
                                                                            flat_land_color = this.getTdColor(val.flat_land, unique_matched_columns, "flat_land");
                                                                            building_age_color = this.getTdColor(val.building_age, unique_matched_columns, "building_age");
                                                                            land_size_color = this.getTdColor(val.land_size, unique_matched_columns, "land_size");
                                                                            internal_size_color = this.getTdColor(val.internal_size, unique_matched_columns, "internal_size");
                                                                            newly_renovated_color = this.getTdColor(val.newly_renovated, unique_matched_columns, "newly_renovated");
                                                                            walking_distance_to_schools_color = this.getTdColor(val.walking_distance_to_schools, unique_matched_columns, "walking_distance_to_schools");
                                                                            walking_distance_to_shoppings_color = this.getTdColor(val.walking_distance_to_shopping, unique_matched_columns, "walking_distance_to_shopping");
                                                                            development_potential_color = this.getTdColor(val.development_potential, unique_matched_columns, "development_potential");
                                                                            swimming_pool_color = this.getTdColor(val.swimming_pool, unique_matched_columns, "swimming_pool");
                                                                            price_range_color = this.getTdColor(val.price_range, unique_matched_columns, "price_range");
                                                                            
                                                                            return <React.Fragment>
                                                                                <tr>
                                                                                    <td bgcolor={name_color}>{val.name}</td>
                                                                                    {/* <td bgcolor={status_color}> {val.status}</td> */}
                                                                                    <td bgcolor={property_address_color}>{val.property_address}</td>
                                                                                    <td bgcolor={property_type_color}>{val.property_type}</td>
                                                                                    <td>{val.partners}</td>
                                                                                    <td>{val.date_entered && moment(val.date_entered).format('DD-MMMM-YYYY')}</td>
                                                                                    <td bgcolor={no_of_bedrooms_color}>{val.no_of_bedrooms}</td>
                                                                                    <td bgcolor={no_of_bathrooms_color}>{val.no_of_bathrooms}</td>
                                                                                    <td bgcolor={no_of_storage_color}>{val.no_of_storage}</td>
                                                                                    <td bgcolor={flat_land_color}>{val.flat_land}</td>
                                                                                    <td bgcolor={building_age_color}>{val.building_age}</td>
                                                                                    <td bgcolor={land_size_color}>{val.land_size}</td>
                                                                                    <td bgcolor={internal_size_color}>{val.internal_size}</td>
                                                                                    <td bgcolor={newly_renovated_color}>{val.newly_renovated}</td>
                                                                                    <td bgcolor={walking_distance_to_schools_color}>{val.walking_distance_to_schools}</td>
                                                                                    <td bgcolor={walking_distance_to_shoppings_color}>{val.walking_distance_to_shopping}</td>
                                                                                    <td bgcolor={development_potential_color}>{val.development_potential}</td>
                                                                                    <td bgcolor={swimming_pool_color}>{val.swimming_pool}</td>
                                                                                    <td bgcolor={price_range_color}>{val.price_range}</td>
                                                                                </tr>
                                                                            </React.Fragment>

                                                                        }) 
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </React.Fragment>
                                                    ) : <React.Fragment>
                                                            <div className="col-lg-12 col-md-12 col-sm-12 alert alert-info mt-5">
                                                                <p className='fw-bold text-center'>No property match available.</p>
                                                            </div>
                                                        </React.Fragment>
                                                }
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        }
                        
                    </Modal.Body>
                </Modal>

                <div className="col-md-12">
                    <div className="card ">
                        <div className="container-fluid dashboard">
                            <div className='row'>
                                <div className='col-lg-8'></div>
                                <div className='col-lg-4'>
                                    <button className="btn btn-primary btn-add-lead" onClick={this.addNewLead}>
                                        <i className="nc-icon nc-simple-add" />
                                    </button>
                                </div>
                            </div>
                            {
                                this.state.isLoading ? (
                                    <center>
                                        <Loader
                                            type="TailSpin"
                                            color="#000000"
                                            height={100}
                                            width={100}
                                            style={{"margin-top" : "100px", "margin-bottom" : "100px"}}
                                        />
                                    </center>
                                ) : (

                                    this.state.leads.length && this.state.leads.length == 0 ? (
                                        <h5>Please refresh the page</h5>
                                    ) : (

                                        <React.Fragment>
                                            <ReactTableFixedColumns
                                            // <ReactTable
                                            data={this.state.leads}
                                            columns={columns}
                                            defaultExpanded={true}
                                            minRows={0}
                                            highlightColor="#d3c38a"
                                            getTrProps={(state, rowInfo) => {
                                                if (rowInfo && rowInfo.row) {
                                                  return {
                                                    onClick: (e, t) => {
                                                        this.setState({
                                                            selected: rowInfo.index,
                                                        })
                                                    },
                                                    className: 'active',
                                                    style: {
                                                        background: rowInfo.index === this.state.selected ? "#f7e9d7" : 'inherit',
                                                        // background: rowInfo.index === this.state.selected ? '#f7e9d7 !important' : '#ffffff',
                                                        // color: rowInfo.index === this.state.selected ? 'red' : '#000',
                                                    }
                                                  }
                                                }else{
                                                  return {}
                                                }
                                            }}
                                        />

                                        </React.Fragment>
                                        
                                    )
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>            
        );
    }
}

export default Leads;

