import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Registration from '../pages/Registration';
import Login from '../pages/Login';
import InvalidTokenPage from '../pages/InvalidTokenPage';
import Leads from '../pages/Leads';
import Dashboard from '../pages/Dashboard';
import UserRoute from './UserRoute';
import { Switch } from 'react-router-dom';
import Registrationexists from '../pages/RegistrationExists';
import Commissions from '../pages/Commissions';
import LeadsReport from '../pages/LeadsReport';
import PropertiesReport from '../pages/PropertiesReport';

function Routes() {
    
    return (
        <Switch>
            
            <UserRoute path="/" exact component={Dashboard}/>
            <UserRoute path="/leads" exact component={Leads}/>
            <UserRoute path="/commissions" exact component={Commissions}/>
            <UserRoute path="/leads-report" exact component={LeadsReport}/>
            <UserRoute path="/dashboard" exact component={Dashboard}/>
            <UserRoute path="/properties-report" exact component={PropertiesReport}/>

        </Switch>
    );

}

export default Routes;
