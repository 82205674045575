import React, { Component } from 'react';
import { request } from '../constants/constants';
import "../css/Login.css";
import cookie from 'react-cookies';
import { Modal } from 'react-bootstrap';

class Registration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: this.props.match.params.token,    
            first_password: '',
            second_password: '',
            errorMessage: '',
            passwordExist: false
        }
    }

    componentDidMount(){
        cookie.remove('user', { path: '/' })
    }

    handleChange = e => {

        this.setState({
            [e.target.name]: e.target.value
        })

    }

    handleFormSubmit = e => {

        e.preventDefault();
        this.setState({
            isLoading: true
        })
        const data = {
            first_password: this.state.first_password,
            second_password: this.state.second_password,
            token: this.state.token
        }

        request('POST', '/auth/save-password', data, false).then(res => {
            console.log("res", res)
            this.setState({
                passwordSaveModal: true,
                successMessage: res.data.message
            })
        }).catch(err => {
            console.log("err", err)

            if(err.response.status == 403){
                this.setState({
                    passwordError: true,
                    errorMessage: err.response.data.message
                })
            }

            if(err.response.status == 500){
                this.setState({
                    passwordError: true,
                    errorMessage: err.response.data.message
                })
            }
        })

    }

    handleClose = () => {
        this.setState({
            passwordError: false,
            passwordSaveModal: false
        })
    }

    handleOkay = () => {
        window.location.href = "/login";
    }

    render() {
        
        return (
            <React.Fragment>
                <Modal show={this.state.passwordError} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error: </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='text-center'>{this.state.errorMessage}</Modal.Body>
                    <Modal.Footer>
                    <button className="btn btn-primary" onClick={this.handleClose}> Close </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.passwordSaveModal} onHide={this.handleClose} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Success: </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='text-center'>{this.state.successMessage}</Modal.Body>
                    <Modal.Footer>
                    <button className="btn btn-primary" onClick={this.handleOkay}> Okay </button>
                    </Modal.Footer>
                </Modal>
                
                <h3 className='text-center mt-5'>Welcome to Loan Tasker Portal.</h3>
                <h6 className='text-center mt-4'>Complete the below fields to create your account.</h6>
                <div className='login-container mt-5'>
                    <form onSubmit={this.handleFormSubmit}>
                        <div className='title text-center'><h3>Registration</h3></div>
                        <div className="form-group mt-2">
                            <label>Password</label>
                            <input 
                                type="password" 
                                name="first_password" 
                                className="form-control" 
                                placeholder="Enter password" 
                                value={this.state.first_password}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label>Confirm Password</label>
                            <input 
                                type="password" 
                                name="second_password" 
                                className="form-control" 
                                placeholder="Confrm password" 
                                value={this.state.second_password}
                                onChange={this.handleChange}
                            />
                        </div>
                        <button type="submit" className="login-button mt-3">Save</button>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

export default Registration;
