import React, { Component } from 'react';

class Sidebar extends Component {
    render() {
        return (
            <div className="sidebar" data-color="white" data-active-color="danger">
                <div className="logo">
                    <a href="/" className="simple-text logo-mini">
                    <div className="logo-image-small">
                        <img src="../assets/img/logo-small.png" />
                    </div>
                    </a>
                    <a href="/" className="simple-text logo-normal">
                        Loan Tasker Portal
                    </a>
                </div>
                <div className="sidebar-wrapper">
                    <ul className="nav">
                        <li className=" ">
                            <a href="/">
                                <i className="nc-icon nc-bank" />
                                <p>Dashboard</p>
                            </a>
                        </li>
                        <li>
                            <a href="/leads">
                                <i className="nc-icon nc-badge" />
                                <p>Leads</p>
                            </a>
                        </li>
                        <li>
                            <a href="/commissions">
                                <i className="nc-icon nc-money-coins" />
                                <p>Commissions</p>
                            </a>
                        </li>
                        <li>
                            <a href="/leads-report">
                                <i className="nc-icon nc-paper" />
                                <p>Leads Report</p>
                            </a>
                        </li>
                        {/* <li>
                            <a href="/properties-report">
                                <i className="nc-icon nc-paper" />
                                <p>properties Report</p>
                            </a>
                        </li> */}
                    </ul>
                </div>
            </div>
        );
    }
}

export default Sidebar;
