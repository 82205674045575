import React, { Component } from 'react';
import Routes from './routes/Routes';
import UnprotectedRoutes from './routes/UnprotectedRoutes';
import { Router } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import cookies from 'react-cookies';
import history from './history';
import { UserProvider } from "./contexts/User/Index";
import Sidebar from './components/Sidebars/Sidebar';
import Header from './components/Headers/Header';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: cookies.load('user')
    }
  }

  setUser = (data) => {
    this.setState({
      user: data
    });
  }

  componentDidMount(){
    if(this.state.user) {
        cookies.save('user', {
          ...this.state.user
        }, {path: '/'});
        this.setState({
          user: cookies.load('user')
        })
    }
  }

  render() {
    return (
      <Router history={history}>
        <UserProvider 
          value={{
            user: this.state.user,
            setUser: this.setUser
          }}
        >

          <div className="wrapper">
            {
              this.state.user && <Sidebar />
            }
            <div className="main-panel">
              {
                this.state.user && <Header />
              }
              <div class="content">
                <Routes />
              </div>
            </div>
            <UnprotectedRoutes />
          </div>
        </UserProvider>
      </Router>
    );
  }
}

export default App;