import React, { Component } from 'react';
import { request } from '../constants/constants';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Loader from 'react-loader-spinner';
import UserContext from '../contexts/User/Index';
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

class LeadsReport extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            lead_conversion_rate: 0,
            report_data: [],
            labels: [],
            is_loading_closed_leads_report: true,
            is_loading_leads_report: true
        }
    }

    componentDidMount(){

        const item_id = this.context.user.user.item_id;

        // const item_id = this.context.user.user.item_id

        // request('GET', `/dashboard/get-lead-conversion-report/${item_id}`, {}, true).then(res => {

        //     const lead_conversion_rate = res.data.data
        //     this.setState({ lead_conversion_rate, isLoading: false})

        // }).catch(err => {
        //     console.log("err", err)
        //     this.setState({ isLoading: false})
        // })

        request('GET', `/dashboard/get-performance-report-growth-closed-leads/${item_id}`, {}, true).then(res => {

            const labels = res.data.dates
            const report_data = [];
           
            labels.map(month => {
                report_data.push(res.data.data[month])
            });

            this.setState({ labels, report_data, is_loading_closed_leads_report: false})

        }).catch(err => {
            console.log("err", err)
            this.setState({ is_loading_closed_leads_report: false})
        })

        request('GET', `/dashboard/get-leads-weekly-report-by-partner/${item_id}`, {}, true).then(res => {

            const lead_report_labels = [];
            const lead_report_data = [];

            for (const [key, value] of Object.entries(res.data.data)) {
                lead_report_labels.push(key)
                lead_report_data.push(value)
            }
           
            this.setState({ lead_report_labels, lead_report_data, is_loading_leads_report: false})

        }).catch(err => {
            console.log("err", err)
            this.setState({ is_loading_leads_report: false})
        })

    }
    
    render() {

        const labels = this.state.labels

        const data = {
            labels: labels,
            datasets: [{
                label: 'Closed Leads',
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgb(255, 99, 132)',
                data: this.state.report_data
            }]
        };

        const lead_report_labels = this.state.lead_report_labels

        const lead_report_data = {
            labels: lead_report_labels,
            datasets: [{
                label: 'Leads Report',
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgb(255, 99, 132)',
                data: this.state.lead_report_data
            }]
        };
        
        return (
            <div>
                {/* <div className='row'>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="card card-stats">
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-5 col-md-4">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="fa fa-percent text-success" />
                                    </div>
                                    </div>
                                    <div className="col-7 col-md-8">
                                    <div className="numbers">
                                        <p className="card-category">Lead Conversion Rate</p>
                                        <p className="card-title">{this.state.lead_conversion_rate}</p><p>
                                        </p></div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer ">
                                <hr />
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="card ">
                            <div className="card-header ">
                                <h5 className="card-title">Closed Leads Chart Performance Report</h5>
                                {/* <p className="card-category">24 Hours performance</p> */}
                            </div>
                            <div className="card-body ">
                                <div
                                    style={{
                                        width: '1000px',
                                        height: '500px',
                                        textAlign: "center"
                                    }}
                                >
                                    {
                                        this.state.is_loading_closed_leads_report ? (
                                            <Loader
                                                type="TailSpin"
                                                color="#000000"
                                                height={100}
                                                width={100}
                                                style={{"margin-top" : "100px", "margin-left" : "700px", "margin-bottom" : "100px"}}
                                            />
                                        ) : (
                                            <Bar data={data} />
                                        )
                                        
                                    }
                                    
                                </div>
                            </div>
                            <div className="card-footer ">
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card ">
                            <div className="card-header ">
                                <h5 className="card-title">Leads Chart Report</h5>
                            </div>
                            <div className="card-body ">
                                <div
                                    style={{
                                        width: '1000px',
                                        height: '500px',
                                        textAlign: "center"
                                    }}
                                >
                                    {
                                        this.state.is_loading_leads_report ? (
                                            <Loader
                                                type="TailSpin"
                                                color="#000000"
                                                height={100}
                                                width={100}
                                                style={{"margin-top" : "100px", "margin-left" : "700px", "margin-bottom" : "100px"}}
                                            />
                                        ) : (
                                            <Bar data={lead_report_data} />
                                        )
                                    }
                                    
                                </div>
                            </div>
                            <div className="card-footer ">
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default LeadsReport;
