import React, { Component } from 'react';
import { Tab, Tabs, Nav, Modal } from 'react-bootstrap';
import cookie from 'react-cookies';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmation: false,
        }
    }

    logout = () => {
        this.setState({
            confirmation: true,
            modalMessage: "Do you really want to log out?"
        })
    }

    logoutFunction = () => {
        cookie.remove("user");
        window.location.href = "/login";
    }

    handleClose = () => {
        this.setState({
            confirmation: false
        })
    }

    render() {
        return (
            <React.Fragment>
                <Modal show={this.state.confirmation}  backdrop="static" onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation: </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='text-center'>{this.state.modalMessage}</Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger" onClick={this.handleClose}> Cancel </button>
                        <button className="btn btn-primary" onClick={this.logoutFunction}> Confirm </button>
                    </Modal.Footer>
                </Modal>

                <nav className="navbar navbar-expand-lg navbar-absolute fixed-top static-top navbar-transparent">
                    <div className="container-fluid">
                        <div className="navbar-wrapper">
                            <div className="navbar-toggle">
                                <button type="button" className="navbar-toggler">
                                    <span className="navbar-toggler-bar bar1" />
                                    <span className="navbar-toggler-bar bar2" />
                                    <span className="navbar-toggler-bar bar3" />
                                </button>
                            </div>
                            {/* <a className="navbar-brand" href="javascript:;">Loadn</a> */}
                        </div>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-bar navbar-kebab" />
                            <span className="navbar-toggler-bar navbar-kebab" />
                            <span className="navbar-toggler-bar navbar-kebab" />
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="navigation">
                            <ul className="navbar-nav">
                                <li className="nav-item btn-rotate dropdown">
                                    <a className="nav-link dropdown-toggle" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="nc-icon nc-bell-55" />
                                        <p><span className="d-lg-none d-md-block">Some Actions</span></p>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                                        <a className="dropdown-item" href="#">Action</a>
                                        <a className="dropdown-item" href="#">Another action</a>
                                        <a className="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link btn-rotate" href="javascript:;">
                                        <i class="fa fa-sign-out-alt" onClick={this.logout}/>
                                        {/* <p><span className="d-lg-none d-md-block">Account</span></p> */}
                                    </a>
                                </li>
                            </ul>   
                        </div>
                    </div>
                </nav>
            </React.Fragment>
        )
    }
}

export default Header;
