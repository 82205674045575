import React, { Component } from 'react';
import { request } from '../constants/constants';
import "../css/Login.css";
import cookie from 'react-cookies';
import { Modal } from 'react-bootstrap';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            showUnauthorisedModal: false,
            showUserInactiveModal: false
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    
    handleFormSubmit = e => {

        e.preventDefault();

        const data = {
            email: this.state.email,
            password: this.state.password
        }
        
        request('POST', '/auth/login', data, false).then(res => {
            console.log("res", res)
            this.setState({
                isLoading: false
            })

            cookie.save('user', res.data, {path: '/'});

            if(res.data.user){
                window.location.href = "/dashboard";
            }  
            
        }).catch(err => {
            console.log(err.response)
            this.setState({
                isLoading: false,
                passwordError: true,
                errorMessage: err.response.data.message
            })
        })
    }

    handleClose = () => {
        this.setState({
            passwordError: false,
            passwordSaveModal: false
        })
    }
  
    render() {
        return (
            <React.Fragment>
                <Modal show={this.state.passwordError} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title><p className='text-danger'>Error:</p></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='text-center'>{this.state.errorMessage}</Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={this.handleClose}> Close </button>
                    </Modal.Footer>
                </Modal>

                <div className='login-container'>
                    <div className='title'><h3>Log in</h3></div>
                    <form onSubmit={this.handleFormSubmit}>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="text" name="email" onChange={this.handleChange} className="form-control" placeholder="Enter email" />
                        </div>
                        <div className="form-group mt-3">
                            <label>Password</label>
                            <input type="password" name="password" onChange={this.handleChange} className="form-control" placeholder="Enter password" />
                        </div>
                        <button type="submit" className="login-button mt-3">Sign in</button>
                        {/* <p className="forgot-password text-right">Forgot <a href="#">password?</a></p> */}
                    </form>
                </div>
            </React.Fragment>
            
        );
    }    
}

export default Login;


  
  
  

